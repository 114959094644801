import "./styles.css";

export default function App() {
  return (
    <div className="App">
      <h1>POTION RECIPES</h1>
      <hr />
Hey guys my name is Angelo Ipanaque i am going to show you the list of potion recipes. this will include
<ol>
<li>ingrediants</li>
<li>how to get the ingrediants</li>
<li>and the tutorials</li>
</ol>
</div>
    )
    ;}